<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title><i class="fa fa-cubes"></i> Millibase - Familles</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-row>
        <ion-col>
          <france-connect-button auth-mode></france-connect-button>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script>
// @ is an alias to /src

import {
  IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonRow, IonCol
} from '@ionic/vue'
import FranceConnectButton from "@/components/Login/FranceConnectButton.vue"


export default {
  name: 'france-connect-login',
  components: {
    FranceConnectButton,
    IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonRow, IonCol,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style>
</style>
