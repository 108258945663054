<template>
  <span v-if="frontendInstance && isEnabled">
    <div class="france-connect-title" v-if="!authMode">
      <b>Je me connecte avec FranceConnect</b>
    </div>
    <div class="france-connect">
      <div class="france-connect-intro">
        FranceConnect est la solution proposée par l'État pour sécuriser <br />
        et simplifier la connexion à vos services en ligne
      </div>
      <div class="france-connect-signin">
        <a class="btn btn-primary" href @click.prevent="signIn()">
          <img src="@/assets/img/franceconnect-bouton.svg" />
          <img src="@/assets/img/franceconnect-bouton-hover.svg" class="hover" />
        </a>
      </div>
      <div class="france-connect-about">
        <a href="https://franceconnect.gouv.fr/" target="_blank">Qu'est-ce que FranceConnect ?</a>
      </div>
    </div>
    <div class="france-connect-alternative" v-if="!authMode">
      <div class="france-connect-sep1"></div>
      <div class="france-connect-or">OU</div>
      <div class="france-connect-sep2"></div>
      <div class="signin-title"><b>Je me connecte avec mes identifiants</b></div>
    </div>
    <div v-else-if="error" >
      <ion-card color="danger">
        <ion-card-content>{{ error }}</ion-card-content>
      </ion-card>
    </div>
    <div v-else>
      <ion-card color="primary">
        <ion-card-content>Connexion en cours</ion-card-content>
      </ion-card>
    </div>
  </span>
</template>

<script>
import { IonCard, IonCardContent } from '@ionic/vue'
import { mapActions } from 'vuex'
import { loadFrontendInstance } from '@/apis/settings'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import router from '@/router'
import store from '@/store'
import { makeUser } from '@/types/auth'
import { storeFranceConnectData, loadFranceConnectData, storeFranceConnectLogout } from '@/types/france_connect'

export default {
  name: 'FranceConnectButton',
  components: { IonCard, IonCardContent, },
  mixins: [BackendMixin],
  data() {
    return {
      error: '',
    }
  },
  props: {
    franceConnect: {
      type: Boolean,
      default: false,
    },
    authMode: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    await this.auth()
  },
  computed: {
    frontendInstance() {
      const frontendInstance = store.getters.frontendInstance
      if (frontendInstance) {
        return frontendInstance
      }
      return null
    },
    backendInstance() {
      const frontendInstance = store.getters.frontendInstance
      if (frontendInstance) {
        return frontendInstance.backend || null
      }
      return null
    },
    isEnabled() {
      return +this.getFrontendSetting('france_connect') === 1
    },
  },
  watch: {
    authMode: async function() {
      await this.auth()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async signIn() {
      if (!this.authMode) {
        const url = '/users/france-connect/login/'
        const appUrl = window.location.origin
        const backendApi = new BackendApi('post', url, this.backendInstance)
        try {
          const resp = await backendApi.callApi({ 'app_url': appUrl, })
          storeFranceConnectData({ state: resp.data.state, nonce: resp.data.nonce, })
          window.location.href = resp.data.url
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
    },
    async auth() {
      if (this.authMode) {
        await loadFrontendInstance()
        const url = '/users/france-connect/auth/'
        const code = this.$route.query.code
        const state = this.$route.query.state
        const fcData = loadFranceConnectData()
        if (state === fcData.state) {
          const appUrl = window.location.origin
          const backendApi = new BackendApi('post', url, this.backendInstance)
          try {
            const resp = await backendApi.callApi({'app_url': appUrl, code: code, nonce: fcData.nonce, })
            if (resp.data.success) {
              const user = makeUser(resp.data, this.backendInstance)
              await store.dispatch('setUser', user)
              await router.push({ name: 'home', })
              storeFranceConnectLogout(resp.data.logout)
            } else {
              this.error = resp.data.message
              setTimeout(
                  function() {
                    window.location.href = resp.data.logout
                  },
                  2000
              )
            }
          } catch (err) {
            this.addError(this.getErrorText(err))
            await router.push({ name: 'login', })
          }
        } else {
          this.addError('Etat France Connect invalide')
          await router.push({ name: 'login', })
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.france-connect-intro {
  text-align: center;
  margin-bottom: 10px;
}
.france-connect {
  display: block;
  text-align: center;
  margin: 0 15px;
  padding: 10px 0;
}
.france-connect-about {
  display: block;
  text-align: center;
}
.france-connect img.hover {
  display: none;
}
.france-connect:hover img {
  display: none;
}
.france-connect:hover img.hover {
  display: inline-block;
}
.france-connect-title {
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}
.signin-title {
    margin-top: 20px;
}
.france-connect-alternative {
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}
.france-connect-or {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  margin-top: 5px;
  width: 10%;
}
.france-connect-sep1, .france-connect-sep2 {
  display: inline-block;
  text-align: center;
  margin-left: 10%;
  width: 35%;
  content: ' ';
  border-bottom: solid 1px var(--ion-color-medium);
  vertical-align: center;
}
.france-connect-sep2 {
  margin-left: 0;
  margin-right: 10%;
}
</style>
